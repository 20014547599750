/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ba347d27-252c-4efe-a158-325a4651b8a2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IEyIZHGBH",
    "aws_user_pools_web_client_id": "1sbpli0gpc53024f7jduiccpbc",
    "oauth": {},
    "aws_user_files_s3_bucket": "pollv3155642-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
