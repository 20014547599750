import React, { useState } from 'react'
import './App.css'

//Nishant: Also need to think about how to refresh browser cache.

//import Switch from 'react-ios-switch'
//import Advanced from './examples/Advanced'
//import Simple from './examples/Simple'
//import Try from './examples/Try'

import Questions from './screens/Questions'
import Share from './components/Share'

function App () {
  const [showAdvanced, setShowAdvanced] = useState(true)
  const shareUrl = 'http://poll.pages.dev';
  const title = 'Social Poll!';

  return (
    <div className='app'>
      <div className='header'><div className='row'><h1 style={{ color: '#fff' }}>This or That</h1></div></div>
      <div className='body'><Questions/></div>
      <div className='footer'><Share/></div>
    </div>
  )
}

export default App
