import React, { useState } from 'react'
//import { questionsData } from '../data/questionsData' // for testing only.
import { initData } from '../data/initData' // for intial loading.
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import ProgressBar from "../components/ProgressBar";
import ResultsBar from "../components/ResultsBar";
import AWSClient from '../components/AWSClient';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './../aws-exports';
Amplify.configure(awsconfig);

export default class Questions extends React.Component {
  constructor() {
    super();

    this.state = {
      qData : initData,
      currentQuestion : initData[0],
      currentIndex : 0,
      visitorId : '',
      showResults : false
    }

    this.setVisitorId(); // Set the browser fingerprint.
    this.getQuestions(); // Load the questions from the S3 bucket.
  }

  setVisitorId = async () => {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load()
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    const vID = result.visitorId;
    this.state.visitorId = vID;
    AWSClient.writeDeviceInfo(vID);
  }

  setCurrentQuestion (index) {
    if (index < this.state.qData.length) {
      this.setState({
        currentIndex : index,
        currentQuestion : this.state.qData[index]
      });
    }
    else { //Nishant: This needs to be updated. This is good for testing only. We don't want to loop here.
      this.setState({
        currentIndex : 0,
        currentQuestion : this.state.qData[0],
        showResults : true
      });
    }
  }

  getQuestions = async () => {
    const result = await Storage.get(`questions/questions.json`, { download: true });
    result.Body.text().then(str => {
      var strArr = JSON.parse(str);
      //store this as part of the state
      this.setState({
        qData : strArr,
        currentIndex : 0
      });
      this.setCurrentQuestion(0)
    });
  }

  nextClicked(currentQuestion) {
    let newCounter = this.state.currentIndex + 1
    // Move to the next question
    this.setCurrentQuestion (newCounter);
  }

  clicked(answer, currentQuestion) {
    console.log('answer: ' + answer + ' from: ' + this.state.visitorId)
    let newCounter = this.state.currentIndex + 1

    // Write the answer to S3 bucket as well.
    // The response should be a json with the following
    // 1) Question ID
    // 2) Answer
    // 3) Time
    // 5) Device ID
    const contentJson = {
      questionID : this.state.currentQuestion.questionID,
      answer : answer,
      time : new Date().toISOString(),
      deviceId : this.state.visitorId
    }

    // We will store the results in the path of question ID and the answer. That will help
    // with fast computation of the response without looking into the file itself.
    // We will use the current time as a random number for the filename for the answer.
    const path = this.state.currentQuestion.questionID + '/' + answer;
    AWSClient.writeAnswer(path, JSON.stringify(contentJson));

    // Move to the next question
    this.setCurrentQuestion (newCounter);
  }

  render() {
    if (this.state.showResults == false) { // show the poll
      if (this.state.currentQuestion.questionType === '1Image') {
        return (
          <div>
            <link href='https://fonts.googleapis.com/css?family=Damion&display=swap' rel='stylesheet' />
            <link href='https://fonts.googleapis.com/css?family=Alatsi&display=swap' rel='stylesheet' />
            <h3>{this.state.currentQuestion.questionText}</h3>
            <div className='cardContent'><div style={{ backgroundImage: 'url(' + this.state.currentQuestion.Image1Url + ')' }} className='card'></div></div>
            <div className='buttons'>
              <yesButton onClick={(dir) => this.clicked(this.state.currentQuestion.Answer1, this.state.currentQuestion)}>{this.state.currentQuestion.Answer1}</yesButton>
              <noButton onClick={(dir) => this.clicked(this.state.currentQuestion.Answer2, this.state.currentQuestion)}>{this.state.currentQuestion.Answer2}</noButton>
            </div>
            <ProgressBar completed={100*(this.state.currentIndex+1)/this.state.qData.length} />
          </div>
        )
      }
      else {
        return (
          <div>
            <link href='https://fonts.googleapis.com/css?family=Damion&display=swap' rel='stylesheet' />
            <link href='https://fonts.googleapis.com/css?family=Alatsi&display=swap' rel='stylesheet' />
            <h3>{this.state.currentQuestion.questionText}</h3>
            <div className='buttons'>
              <imageButton onClick={(dir) => this.clicked(this.state.currentQuestion.Answer1, this.state.currentQuestion)}>
                <div style={{ backgroundImage: 'url(' + this.state.currentQuestion.Image1Url + ')' }} className='buttonCard'></div>
              </imageButton>
              <imageButton onClick={(dir) => this.clicked(this.state.currentQuestion.Answer2, this.state.currentQuestion)}>
                <div style={{ backgroundImage: 'url(' + this.state.currentQuestion.Image2Url + ')' }} className='buttonCard'></div>
              </imageButton>
            </div>
            <ProgressBar completed={100*(this.state.currentIndex+1)/this.state.qData.length} />
          </div>
        )
      }
    }
    else { // show poll results
      if (this.state.currentQuestion.questionType === '1Image') {
        return (
          <div>
            <link href='https://fonts.googleapis.com/css?family=Damion&display=swap' rel='stylesheet' />
            <link href='https://fonts.googleapis.com/css?family=Alatsi&display=swap' rel='stylesheet' />
            <h3>Results</h3>
            <h3>{this.state.currentQuestion.questionText}</h3>
            <div className='cardContent'><div style={{ backgroundImage: 'url(' + this.state.currentQuestion.Image1Url + ')' }} className='card'></div></div>
            <div><span>Yes: 77% No: 23%</span></div>
            <div className='buttons'>
              <button onClick={(dir) => this.nextClicked(this.state.currentQuestion)}>Next</button>
            </div>
            <ProgressBar completed={100*(this.state.currentIndex+1)/this.state.qData.length} />
          </div>
        )
      }
      else {
        return (
          <div>
            <link href='https://fonts.googleapis.com/css?family=Damion&display=swap' rel='stylesheet' />
            <link href='https://fonts.googleapis.com/css?family=Alatsi&display=swap' rel='stylesheet' />
            <h3>{this.state.currentQuestion.questionText}</h3>
            <div className='buttons'>
              <div style={{ backgroundImage: 'url(' + this.state.currentQuestion.Image1Url + ')' }} className='resultCard'></div>
              <div style={{ backgroundImage: 'url(' + this.state.currentQuestion.Image2Url + ')' }} className='resultCard'></div>
            </div>
            <div className='buttons'>
              <button onClick={(dir) => this.nextClicked(this.state.currentQuestion)}>Next</button>
            </div>
            <ProgressBar completed={100*(this.state.currentIndex+1)/this.state.qData.length} />
          </div>
        )
      }
    }
  }
}
