export const initData = [
  {
    "version" : "v1",
    "category" : "Politics",
    "questionID" : "0",
    "questionType" : "1Image",
    "questionText" : "We are loading...",
    "Image1Url" : "https://theoccasionalnomad.files.wordpress.com/2015/08/keep-calm-and-just-wait-25.png",
    "Image2Url" : "",
    "Answer1" : "Cool!",
    "Answer2" : "Whatever",
    "ExpiryDate" : "1/20/2019"
  }
]
