import Amplify, { Storage } from 'aws-amplify';
import awsmobile from './../aws-exports';
Amplify.configure(awsmobile);

export default class AWSClient {

  static writeAnswer(path, answer) {
    var fileName = Math.floor(Date.now()/1000);
    Storage.put('answers/'+path+'/'+fileName+'.json', answer)
    .then (result => console.log(result))
    .catch(err => console.log(err));
  }

  static writeDeviceInfo (deviceId) {
    var stamp = Math.floor(Date.now()/1000) // unix time in seconds.

    const contentJson = {
      deviceId : deviceId,
      time: stamp
    };

    Storage.put('devices/'+ deviceId + "-" + stamp + ".json", JSON.stringify(contentJson))
    .then (result => console.log(result))
    .catch(err => console.log(err));
  }
}
